body {
    background-color: #171717;
    max-width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.a-default, a {
    text-decoration: none;
    color: #2d6da3;
}

.homeautomation {
    background-color: #171717;
    height: 100%;
    margin: 0 auto;
    color: white;
}

.container {
    pointer-events: none;
}

.container > div {
    pointer-events: none;
}

.container > div > img {
    pointer-events: auto;
}

canvas {
    pointer-events: auto;
}

.navBarWrapper {
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;
    background-color: black;
    height: 60px;
    z-index: 5;
}

.navBar {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.navItem {
    flex: 1 auto;
    color: rgba(255, 255, 255, 0.8);
}

.navBarTitle {
    font-weight: bold;
}


.home-wrapper {
    overflow: hidden;
    max-height: calc(100% - 60px);
}

.imageMapperPosition {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 1517px;
}

.backgroundGlow {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
    user-select: none;
}

.houseBaseWrapper {
    padding: 20px;
}

.houseBase {
    max-width: 100%;
    max-height: 100%;
    z-index: 2;
}

.houseBaseFaded {
    position: absolute;
    left:0px;
    top:0px;
    opacity: 0.5;
}

.houseWrapperWrapper {
    padding: 50px;
    margin-top: 16px;
}

.container {
    position: absolute;
    top: 0;
}

.room > img {
    cursor: pointer;
}

.houseWrapperCenter {
    position: relative;
}

.room {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}


.houseWrapper {
    z-index: 2;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.floorplanWrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.floorplanHome {
    font-size: 10px;
    opacity: 0.5;
}

.floorplanTitleWrapper {
    display: flex;
    align-items: baseline;
    margin-top: 8px;
}

.floorplanTitle.selected {
    font-weight: bold;
    font-size: 24px;
    opacity: 1;
    margin-right: 40px;
}

.floorplanTitle {
    margin-right: 40px;
    opacity: 0.4;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
}

.sprite {
    position: relative;
    z-index: 3;
    opacity: 100%;
    transition: 0.2s ease;
    max-width: 100%;
}

.sprite.hidden {
    opacity: 0%;
}

.popUpOverlay, .sidebarOverlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: auto;
}

.sidebarOverlay {
    transition: 0.2s ease;
}

.sidebarOverlayHidden {
    background-color: rgba(0, 0, 0, 0.0);
    pointer-events: none;
}

.sidebar, .popUp {
    background-color: rgba(47, 47, 47, 1);
    color: #a5a5a5;
    z-index: 11;
    position: relative;


}

.sidebar {
    width: 30%;
    max-width: 400px;
    height: 100%;
    transform: translateX(-100%);
    transition: 0.2s ease;
}

.sidebarOut {
    transform: translateX(0%) !important;
}

.popUp {
    width: 70%;
    margin: 0 auto;
    position: relative;
    top: 40px;
    border-radius: 10px;
    padding: 16px;
}

.sectionScrollContainer {
    max-height: calc(100vh - 220px);
    overflow: hidden;
}

.popUpRoomName {
    text-align: center;
    font-weight: bold;
    color: #cfcfcf;
    padding-bottom: 16px;
    margin-bottom: 16px;
    margin-left: -16px;
    margin-right: -16px;
    font-size: 16px;
    border-bottom: 1px solid #484848;
}

.popUpMusicSection {
    display: flex;
}

.musicSong {
    font-weight: bold;
    font-size: 20px;
}

.musicArtist {
    opacity: 0.75;
    font-size: 14px;
    margin-top: 8px;
}

.musicTime {
    position: relative;
    height: 4px;
    width: 100%;
    margin-top: 16px;
}

.musicIndicator {
    width: 2px;
    background-color: white;
    height: 12px;
    top: -4px;
    position: absolute;
    z-index: 6;
}

.musicProgress {
    background-color: #1db954;
    z-index: 5;
}

.musicDuration {
    background-color: #5a5a5a;
    width: 100%;
    z-index: 4;
}

.musicDuration, .musicProgress {
    height: 4px;
    position: absolute;
    border-radius: 10px;
}

.musicNowPlayingContainer {
    flex: 1 auto;
    padding: 24px;
    padding-top: 0px;
}

.musicControlsContainer {
    flex: 0 0 90px;
}

.musicAlbumArtworkContainer {
    display: flex;
    height: 120px;
    width: 120px;
    flex: 0 0 120px;
    justify-content: center;
    align-items: center;
}

.musicAlbumArtwork {
    max-height: 100%;
    max-width: 100%,
}

.popUpSection {
    border-radius: 10px;
    background-color: #404040;
    padding: 16px;
}

.popUpSection:not(:first-child) {
    margin-top: 24px;
}

.popUpSectionRow {
    display: flex;
    justify-content: flex-start;
    height: 32px;
    align-items: center;
}

.popUpSectionTitle {
    font-weight: bold;
    width:200px;
}

.popUpSectionInput {
    width: 50px;
}

.action {
    width:50px;
}

.popUpSectionHeader {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #484848;
    opacity: 0.5;
}

.alarmStatusContainer {
    font-size: 16px;
}

.alarmStatusIndicator {
    font-weight: bold;
}

.sidebarAction {

}

.sidebarRoomName {
    padding: 10px;
    font-size: 20px;
}

.sidebarScrollContainer {
    margin: 10px;
}

.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}
.lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}
.lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}
.lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}
.lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}
.lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}
.lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}
.lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}
.lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}
.lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}
.lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}
.lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}
@keyframes lds-default {
    0%, 20%, 80%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

.loginContainer {
    margin-left:auto;
    margin-left:auto;
    color: white;
}